/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
// @import '@angular/material/theming';
@include mat.core();

// @import '~@angular/material/theming';
// @include mat-core();
// $my-app-primary: mat-palette($mat-blue-grey);
// $my-app-accent:  mat-palette($mat-pink, 500, 900, A100);
// $my-app-warn:    mat-palette($mat-deep-orange);
// $my-palette: mat.$indigo-palette;

$m2m-palette-primary: (
  50: #e7eaf0,
  100: #c2cbd9,
  200: #9aa8c0,
  300: #7285a6,
  400: #536b93,
  500: #355180,
  600: #304a78,
  700: #28406d,
  800: #223763,
  900: #162750,
  A100: #8cabff,
  A200: #5985ff,
  A400: #265fff,
  A700: #0d4dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$m2m-palette-accent: (
  50: #f6e7f2,
  100: #e8c3de,
  200: #d89bc9,
  300: #c872b3,
  400: #bd54a2,
  500: #b13692,
  600: #aa308a,
  700: #a1297f,
  800: #982275,
  900: #881663,
  A100: #ffbee8,
  A200: #ff8bd6,
  A400: #ff58c4,
  A700: #ff3ebb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$m2m-palette-warn: (
  50: #ffe4e4,
  100: #ffbcbc,
  200: #ff9090,
  300: #ff6363,
  400: #ff4141,
  500: #ff2020,
  600: #ff1c1c,
  700: #ff1818,
  800: #ff1313,
  900: #ff0b0b,
  A100: #ffffff,
  A200: #fff6f6,
  A400: #ffc3c3,
  A700: #ffa9a9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$m2m-app-primary: mat.define-palette($m2m-palette-primary, 900);
$m2m-app-accent: mat.define-palette($m2m-palette-accent, 900);
$m2m-app-warn: mat.define-palette($m2m-palette-warn, 900);

$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $m2m-app-primary,
      accent: $m2m-app-accent,
      warn: $m2m-app-warn
    )
  )
);

// THIS
// $my-app-primary: mat.define-palette(mat.$indigo-palette);
// $my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $my-app-warn: mat.define-palette(mat.$red-palette);

// $my-app-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $my-app-primary,
//       accent: $my-app-accent,
//       warn: $my-app-warn,
//     ),
//   )
// );

@include mat.all-component-themes($my-app-theme);

// .dark-theme {
//   color: $light-primary-text;
//   $dark-primary: mat-palette($mat-yellow);
//   $dark-accent: mat-palette($mat-amber, A400, A100, A700);
//   $dark-warn: mat-palette($mat-red);
//   $dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

//   @include angular-material-theme($dark-theme);
// }

// $color-one: #ffffff;
// $color-two: #e4f0ec;
// $color-three: #000;

$color-yellow: #fff217;
$color-primary: mat.get-color-from-palette($m2m-palette-primary, 900);
$color-primary-contrast: mat.get-contrast-color-from-palette($m2m-palette-primary, 900);
$color-bg-grey: whitesmoke;
$color-bg-grey-contrast: black;
$color-bg-white: #fff;
$color-bg-white: #000;

// :root {
//   --color-yellow: $color-yellow;
//   --color-primary: $color-primary;
//   --color-primary-contrasty: $color-primary-contrasty;
//   --color-bg-grey: $color-bg-grey;
//   --color-bg-grey-contrast: $color-bg-grey-contrast;
// }
:root {
  --color-yellow: #fff217;
  --color-primary: #355180;
  --color-primary-contrast: #fff;
  --color-bg-grey: whitesmoke;
  --color-bg-grey-contrast: black;
  --color-bg-white: #fff;
  --color-bg-white-contrast: #000;
}

@font-face {
  font-family: "Fira Sans", sans-serif; // "Haettenschweiler";
  src: local("FiraSans"), url(./assets/fonts/FiraSans-Regular.ttf);
  font-style: normal;
}

html,
body {
  // height: 100%;
  height: fit-content;
}
body {
  margin: 0;
}

*,
body {
  // font-family: "FiraSans";
  font-family: "Open Sans", sans-serif;
  // letter-spacing: 1px;
  // font-weight: 100;
}

.mat-button-toggle-checked {
  background-color: $color-primary !important;
  color: $color-primary-contrast !important;
}

/** 
  * Mat Elements
  */

mat-card {
  margin: 0.5rem 0;
  padding: 1rem;

  mat-card-header {
    margin-bottom: 1rem;
  }
}

mat-form-field {
  width: 100%;
  margin: auto;
}

mat-divider {
  margin: 0.75rem auto !important;
}

// mat-card-title {
//   padding: 2rem;
// }

// mat-card-content {
//   margin: auto;
//   width: 100%;
//   padding: 2rem;
// }

// mat-card-actions {
//   padding: 2rem;
// }

.app__button {
  min-width: 20% !important;
  margin-left: 0.5rem;
}

.app__button--large {
  width: 100% !important;
}

mat-spinner {
  margin: auto;
  height: 100vh;
}

/**
  * Layouts
  */

// .app__container {
//   height: 100%;

//   display: flex;
//   flex-flow: column;
// }

// .app__toolbar {
//   background-color: $color-bg-grey !important;
//   background: $color-bg-grey !important;
//   color: $color-bg-grey-contrast !important;

//   padding: 3em 1.5rem;
//   height: 9rem !important;
//   flex: 0 1 auto;
//   border-bottom: 4px solid $color-yellow;
// }

// .app__sidenav-container {
//   flex: 1 1 auto;
// }

//  .app__main-content {
//    padding: 1rem;
//  }

.language-selector__container {
  float: right;
  margin-left: auto;
  margin-right: 1rem;
}

.language-selector__icon {
  height: 100% !important;
}

/**
  * Forms
  */
// .page__card {
//   width: 70%;

//   .card__title {
//     padding: 1rem 2rem;
//   }

//   .card__content {
//     padding: 1rem 2rem;
//   }

//   .card__actions {
//     padding: 0 2rem 1rem 2rem;
//   }
// }

// .card__title {
//   padding: 1rem 2rem;
// }

// .card__content {
//   padding: 1rem 2rem;
// }

// .card__actions {
//   padding: 0 2rem 1rem 2rem;
// }

// .card__actions__button {
//   min-width: 25%;
//   float: right;
//   margin: 0 0 0 auto;
//   border-radius: 0.5rem;
// }

// .card__actions__button--large {
//   width: 100% !important;
// }

/**
  * Forms (Login)
  */
.form__card.authentication__card {
  width: 60%;
  border: none !important;
  box-shadow: none !important;

  .login__form__title {
    text-align: center;
    margin: 2rem auto;
  }

  .login__form__image {
    margin: auto;

    img {
      background-size: contain;
      max-width: 80%;
      max-height: 100%;
      padding: 1.5rem;
    }
  }

  .login__form__actions {
    float: right;
    // width: 100%;
    // padding: 0.5rem;

    button {
      border-radius: 0.5rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .form__card.authentication__card {
    width: 95%;
    .login__form__image {
      margin: auto;
      text-align: center;

      img {
        max-width: 60%;
      }
    }
  }
}
/**
  * Steppers
  */

// .view__card {
//   padding: 1rem 2rem;
//   margin-bottom: 0.5rem;

//   .card__avatar {
//     background-size: cover;
//   }

//   .card__actions__button {
//     min-width: 20%;
//     margin-left: 0.5rem;
//     border-radius: 0.5rem;
//   }
// }

/**
  * Form (Files)
  */

.file-container {
  width: 100%;
  height: 200px;
  border: 2px dashed black;
  position: relative;
}

.file-container__upload-button {
  width: 100%;
  height: 100%;
  background-color: white;
}

.file-container__cancel-button {
  transform: scale(0.75) !important;
  background-color: white !important;
  position: absolute !important;
  top: -24px;
  right: -24px;
  // border: 1px solid red !important;
  // color: red !important;

  mat-icon {
    vertical-align: super !important;
  }
}

.file-container__image {
  max-width: 75%;
  max-height: 100%;
}

.upload-button-container {
  text-align: center;
  margin: auto;
  border-radius: 0.5rem;
}

/**
  * Dialogs
  */

:host {
  display: flex;
}

.app__dialog-content {
  text-align: center;
}

.app__dialog-actions {
  justify-content: center !important;
}

/**
  * Filters
  */

.filter-container {
  display: flex;
}

.filter-container__child-a {
  flex: 5;
  margin-right: 20px;
}

.filter-container__child-b {
  flex: 1;
}

/**
  * View Table 
  */

.table__action-button {
  mat-icon {
    vertical-align: super !important;
  }
}

.table__cell--nowrap {
  white-space: nowrap;
}

.table__cell--justify {
  text-align: justify;
}

.table__avatar-container {
  width: 15%;
}

.table__avatar-container__image {
  width: 80%;
  margin: 10%;
}

tr:nth-child(even) {
  td {
    background-color: var(--color-bg-grey);
  }
}

/**
  * Cards
  */

.app__partner {
  .view__card.card__event {
    border-radius: 0.5rem;
    border: 3px solid var(--color-yellow);
    box-shadow: none;
    background-color: var(--color-bg-white) !important;
  }
}

.app__public {
  .view__card.card__event {
    border-radius: 0.5rem;
    border: 3px solid var(--color-yellow) !important;
    box-shadow: none;
    background-color: var(--color-bg-grey) !important;
  }
  .view__card.card__partner {
    border-radius: 0.5rem;
    border: 3px solid var(--color-yellow) !important;
    box-shadow: none;
    background-color: var(--color-bg-grey) !important;

    cursor: pointer;
  }

  .view__card.card__partner:hover {
    opacity: 0.75;
  }
}
.page__table,
.page__list,
.page__card,
.form__card {
  width: 70%;
  margin: auto;
}
.page__card,
.form__card,
.table__card {
  border-radius: 0.5rem !important;
  border: 3px solid var(--color-yellow) !important;
  box-shadow: none !important;
  background-color: var(--color-bg-white) !important;
}

.page__list.framed__list {
  width: 95% !important;
  margin: auto;
}
.framed__card.page__card,
.framed__card.stepper__card {
  width: 95%;
  border-radius: 0.5rem !important;
  border: 3px solid var(--color-yellow) !important;
  box-shadow: none !important;
  background-color: var(--color-bg-grey) !important;
  margin: auto;
  margin-bottom: 0.5rem;

  .stepper__actions {
    display: flex;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 800px) {
  .page__table,
  .page__list,
  .page__card,
  .form__card {
    width: 95% !important;
  }

  .page__list {
    .filter-container {
      display: block;
      margin: auto;
    }
  }

  .framed__card.page__card,
  .page__list.framed__list {
    width: 95% !important;
  }

  .framed__card.stepper__card {
    width: 95%;

    padding: 0 !important;
    mat-card-content {
      padding: 0;
    }
  }
}

.icon__container {
  display: flex;

  mat-icon {
    width: fit-content;
  }

  mat-icon,
  p {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

/**
  * Responsive
  */

@media only screen and (max-width: 800px) {
  // .view__list {
  //   width: 95%;
  //   margin: auto;
  // }

  // .form__card,
  // .stepper__card {
  //   width: 95%;

  //   mat-card-content {
  //     padding: 0.25rem !important;
  //   }
  //   .date-selector__container {
  //     margin-bottom: 1rem;
  //   }
  // }

  // .form__card.authentication__card {
  //   width: 95%;
  // }

  // .view__card {
  //   padding: 1rem 0.25rem;
  //   margin-bottom: 0.5rem;

  //   .card__avatar {
  //     background-size: cover;
  //   }

  //   .card__actions__button {
  //     width: 100%;
  //     margin: 1rem 0;
  //     border-radius: 0.5rem;
  //   }
  // }
}
